<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div :class="['tabs', 'c-tabs']" :id="id">
		<ul class='tabs__header '>
			<li 
				v-for='tab in tabs' 
				:key='tab.id'
				:class="[ 'c-tab', tab.id == selectedTabId ? 'selected' : '' ]"
				@click="onSelectTab(tab)"
			>
				{{ tab.title }}
			</li>
		</ul>
		<div v-show="hasContent">
			<slot></slot>
		</div>
	</div>
</template>






<script>
// Credit: https://www.youtube.com/watch?v=ROGeJxI2L58
// Credit: https://github.com/mattmaribojoc/learn-vue-tab

import { ref , provide } from 'vue';
export default {
	setup(props, { slots }) 
	{
		//const tabTitles = ref(slots.default().map((tab) => tab.props.title));

		//const selectedTitle = ref(tabTitles.value[0]);
		
		//provide("selectedTitle", selectedTitle);


		
		const tabs = ref(slots.default().map((tab) => {
			return { title: tab.props.title, id: tab.props.id };
		}));

		const selectedTabId = ref(tabs.value[0].id);
		//provide("selectedId", selectedTabId);


		// let initTabId = '';
		// if (props.initialTabId)
		// {
		// 	const _this = this;
		// 	initTabId.selectedTabId = tabs.find(z => z.id == props.initialTabId);
		// }
		// else
		// {
		// 	initTabId = tabs.value[0].id;
		// }


		// const selectedTabId = ref(initTabId);
		// provide("selectedId", selectedTabId);


		return {
			//tabTitles,
			tabs,

			//selectedTitle,
			selectedTabId,
		}
	},





	props: {
		/**
		 * The `id` of this tab set. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: true
		},

		/**
		 * ID of the tab to select and display initially.
		 */
		initialTabId: {
			type: String,
			required: false,
			default: ''
		},


		/**
		 * Set to false to show only the tabs themselvs and hide the content area (content can be handled externally).
		 */
		hasContent: {
			type: Boolean,
			required: false,
			default: true
		},
	},


	
	data() {
		return {
		}
	},



	created() {
		//this.tabs = this.$children
	},



	mounted() {
		this.init();
	},


	emits: ["select"],


	methods: {
		init()
		{

			// If an initial tab is defined, select it.
			let initTabId = '';
			if (this.initialTabId)
			{
				//const _this = this;
				const initTab = this.tabs.find(z => z.id == this.initialTabId);
				if (initTab)
				{
					initTabId = initTab.id;
				}

				if (initTabId)
				{
					this.selectedTabId = initTabId;
					//provide("selectedId", this.selectedTabId);
				}
			}
		},





		onSelectTab(tab)
		{
			console.log("tabs.onSelectTab(): tab=", tab, "selectedTabId=", this.selectedTabId);

			this.selectedTabId = tab.id;
			//this.selectedTitle = tab.title;

			this.$emit('select', { tab: tab });
		}
	}
}
</script>






<style lang="scss" scoped>

.tabs
{
	width: 100%;
	margin: 0 auto;
}


/*
//-- Version 1
// .tabs__header
// {
// 	margin-bottom: 10px;
// 	list-style: none;
// 	padding: 0;
// 	display: flex;
// }


// // Verison 1
// .tabs__header li
// {
// 	width: 180px;
// 	text-align: center;
// 	padding: 10px 20px;
// 	margin-right: 10px;
// 	background-color: #dddddd;
// 	border-radius: 5px;
// 	cursor: pointer;
// 	transition: 0.4s all ease-out;
// }


// .tabs__header li.selected
// {
// 	background-color: #0984e3;
// 	color: #ffffff;
// }
*/




/*//-- Version 2*/
.tabs__header
{
	border-bottom: 1px solid #dddddd;
	margin-bottom: 10px;
	list-style: none;
	padding: 0;
	display: flex;
}


/*// Verison 1*/
.tabs__header li
{
	min-width: 180px;
	text-align: center;
	padding: 10px 20px;
	
	border-left: 1px solid #dddddd;
	border-top: 1px solid #dddddd;

	background-color: #ffffff;
	/* border-radius: 5px;*/
	cursor: pointer;
	transition: 0.3s all ease-out;

	font-weight: 600;
}

.tabs__header li:first-child
{
	border-top-left-radius: 6px;
}
.tabs__header li:last-child
{
	border-top-right-radius: 6px;
	border-right: 1px solid #dddddd;
}


.tabs__header li:hover
{
	color: rgb(64, 158, 255);
	// text-decoration: underline;
}




.tabs__header li.selected
{
	background-color: rgb(64, 158, 255);
	color: #ffffff;

	border-top-color: rgb(64, 158, 255);
	border-bottom-color: rgb(64, 158, 255);
	border-left-color: rgb(64, 158, 255);
}

.tabs__header li:last-child.selected
{
	border-right-color: rgb(64, 158, 255);
}

/* 
ul.tabs__header {
	display: block;
	list-style: none;
	margin: 0 0 0 20px;
	padding: 0;
}

ul.tabs__header>li {
	padding: 15px 30px;
	border-radius: 10px;
	margin: 0;
	display: inline-block;
	margin-right: 5px;
	cursor: pointer;
}

ul.tabs__header>li.tab__selected {
	font-weight: bold;
	border-radius: 10px 10px 0 0;
	border-bottom: 8px solid transparent;
}

.c-tab {
	display: inline-block;
	color: black;
	padding: 20px;
	min-width: 800px;
	border-radius: 10px;
	min-height: 400px;
}

.tabs__light .tab {
	background-color: #fff;
}

.tabs__light li {
	background-color: #ddd;
	color: #aaa;
}

.tabs__light li.tab__selected {
	background-color: #fff;
	color: #83FFB3;
}

.tabs__dark .tab {
	background-color: #555;
	color: #eee;
}

.tabs__dark li {
	background-color: #ddd;
	color: #aaa;
}

.tabs__dark li.tab__selected {
	background-color: #555;
	color: white;

} */
</style>